import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'
import store from '@/store'

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
})

instance.interceptors.request.use(
    config => {
        if (store.state.auth.token) {
            config.headers.Authorization = 'Bearer ' + store.state.auth.token
        }

        return config
    },
    err => {
        return Promise.reject(err)
    },
)

instance.interceptors.response.use(
    resp => {
        return resp.data
    },
    err => {
        if (err.response.status === 401) {
            store.dispatch('auth/logout').then(() => router.push('/login'))
        }

        if (err.response.data instanceof Blob) {
            const reader = new FileReader()

            reader.onload = function () {
                const data = JSON.parse(reader.result)

                if (data['errors'] && data['errors'].length > 0) {
                    ElMessage({
                        type: 'error',
                        message: data['errors'][0]['message'],
                    })
                }
            }

            reader.readAsText(err.response.data)
        } else {
            if (err.response && err.response.data) {
                ElMessage({
                    type: 'error',
                    message: err.response.data['errors'][0]['message'],
                })
            } else {
                ElMessage({
                    type: 'error',
                    message: err,
                })
            }
        }

        return Promise.reject(err)
    }
)

export default instance
