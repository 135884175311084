import request from '@/utils/request'

export function createCustomer(data) {
    return request({
        url: '/customer',
        method: 'post',
        data,
    })
}

export function exportCustomer(data) {
    return request({
        url: '/customer/export',
        method: 'post',
        responseType: 'blob',
        data,
    })
}

export function getCustomer(token) {
    return request({
        url: `/customer/${token}`,
        method: 'get',
    })
}

export function getCustomerList(params) {
    return request({
        url: '/customer',
        method: 'get',
        params,
    })
}

export function getCustomerOptions(params) {
    return request({
        url: '/customer/option',
        method: 'get',
        params,
    })
}

export function updateCustomer(data) {
    return request({
        url: `/customer/${data.token}`,
        method: 'put',
        data,
    })
}

export function updateCustomerNote(data) {
    return request({
        url: `/customer/note/${data.token}`,
        method: 'post',
        data,
    })
}

export function updateCustomerPrice(data) {
    return request({
        url: `/customer/price/${data.token}`,
        method: 'post',
        data,
    })
}

export function updateCustomerStatus(data) {
    return request({
        url: `/customer/status/${data.token}`,
        method: 'post',
        data,
    })
}
